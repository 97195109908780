import { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageNotFound from "routes/PageNotFound";
import ProtectedRoute from "routes/ProtectedRoute";
import { ProtectedRoutes, routes } from "./routes";
import "antd/dist/antd.min.css";
import "helper/helper.scss";
import "./App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<div>loading...</div>}>
          <Routes>
            {routes.map((route, i) => {
              return (
                <Route path={route.path} element={route.component} key={i} />
              );
            })}
            <Route element={<ProtectedRoute />}>
              {ProtectedRoutes.map((route, i) => {
                return (
                  <Route path={route.path} element={route.component} key={i} />
                );
              })}
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
