export const baseUrl = process.env.REACT_APP_API_URL;

export const step = [
  {
    name: "Personal Information",
    isActive: true,
    id: 1,
  },
  {
    name: "Contact Details",
    isActive: false,
    id: 2,
  },
  {
    name: "Emergency Contact Details",
    isActive: false,
    id: 3,
  },
  {
    name: "Referred  By",
    isActive: false,
    id: 4,
  },
  {
    name: "Insurance Details",
    isActive: false,
    id: 5,
  },
];

export const STEP_TYPE = {
  PERSONAL_INFO: "Personal Information",
  CONTACT_DETAILS: "Contact Details",
  EMERGENCY_CONTACT: "Emergency Contact Details",
  REFERRED: "Referred  By",
  INSURANCE: "Insurance Details",
};

export const STATIC_PAGE_TYPE = {
  ABOUT_US: "about",
  COMMUNITY_RULES: "Community Rules",
  PRIVACY_POLICY: "privacy",
  TNC: "terms",
  FAQ: "FAQs",
  HIPPA: "hippa",
};

export const STATIC_PAGE_NAME = {
  ABOUT_US: "About US",
  COMMUNITY_RULES: "Community Rules",
  PRIVACY_POLICY: "Privacy Policy",
  TNC: "Terms & Conditions",
  FAQ: "FAQ's",
  HIPPA: "HIPPA",
};

export const STATIC_PAGES_MENU = [
  {
    name: STATIC_PAGE_NAME.ABOUT_US,
    path: "/about-us",
  },
  {
    name: STATIC_PAGE_NAME.PRIVACY_POLICY,
    path: "/privacy-policy",
  },
  {
    name: STATIC_PAGE_NAME.TNC,
    path: "/tnc",
  },
  {
    name: STATIC_PAGE_NAME.HIPPA,
    path: "/hippa",
  },
];

export const STATIC_PAGE_PATHS = [
  "/about-us",
  "/tnc",
  "/privacy-policy",
  "/faq",
  "/community-rules",
];
