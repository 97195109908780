import { STATIC_PAGE_TYPE } from "helper/constant";
import React, { lazy } from "react";
const Home = lazy(() => import("../view/home"));
const Login = lazy(() => import("../view/login"));
const ContactUs = lazy(() => import("../view/contactus"));
const AboutUs = lazy(() => import("../view/aboutus"));
const Signup = lazy(() => import("../view/register"));
const ForgotPassword = lazy(() => import("../view/forgotpassword"));
const CreateProfile = lazy(() => import("../view/createprofile"));
const StaticPage = lazy(() => import("../view/staticPage"));

export const routes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/sign-up",
    component: <Signup />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    path: "/sign-up",
    component: <Signup />,
  },
  {
    path: "/create-profile",
    component: <CreateProfile />,
  },
  {
    path: "/contact-us",
    component: <ContactUs />,
  },
  {
    path: "/about-us",
    component: <StaticPage type={STATIC_PAGE_TYPE.ABOUT_US} />,
  },
  {
    path: "/tnc",
    component: <StaticPage type={STATIC_PAGE_TYPE.TNC} />,
  },
  {
    path: "/privacy-policy",
    component: <StaticPage type={STATIC_PAGE_TYPE.PRIVACY_POLICY} />,
  },
  {
    path: "/faq",
    component: <StaticPage type={STATIC_PAGE_TYPE.FAQ} />,
  },
  {
    path: "/hippa",
    component: <StaticPage type={STATIC_PAGE_TYPE.HIPPA} />,
  },
];

export const ProtectedRoutes = [];
